html {
  scroll-behavior: smooth;
}

.App{
  font-family: 'Cormorant Upright', serif;
}

.darkMode {
  background-color: #0A192F;
  color: #C0C0C0;
}

.colored {
  color: #39ff14;
}

.contact-icons {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  z-index: 2;
  background-color: #ffffff;
}

.contact-icons a {
  color: inherit;
  margin-left: 15px;
  margin-right: 15px;
}

.darkMode .contact-icons {
  background-color: #0A192F;
}

